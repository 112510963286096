.health-tips {
  img {
    width: 100%;
  }

  &__article {
    height: 100%;
    position: relative;
    background: var(--blue--700);
    color: var(--neutral--100);
    a {
      display: block;
      color: var(--neutral--100);
      padding: .5rem 1rem 1rem;
    }
  }

  .col:nth-child(even) {
    .health-tips__article {
      background: var(--orange--900);
    }
  }

  // Show 4 items by default only on tablet-ish
  @include media-breakpoint-between(sm, lg) {
    .col:nth-child(4) {
      display: block;
    }
  }
}
