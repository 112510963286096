.news-articles {
  margin-bottom: 3rem;
}

article.news-article {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;

  h2,
  h3 {
    @extend .like-h4;
    margin-bottom: 0;
  }

  time {
    color: var(--neutral--500);
  }

  .news-articles__cards & {
    img {
      margin-bottom: 1rem;
    }

    h2,
    h3 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      color: var(--blue--600);

      @include media-breakpoint-up(md) {
        -webkit-line-clamp: 3;
      }
    }

  }

  .news-articles__list & {
    margin-bottom: 2rem;
  }
}
