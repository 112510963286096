.adx {
    &-important-notice {
        --adx-border-radius: 0.35rem;
        padding: 1rem;
        background-color: var(--red--100);
        border-radius: calc(var(--adx-border-radius) + 1px);
        margin-bottom: 1rem;
        * {
            color: var(--red--200);
        }
    }
}