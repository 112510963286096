.template-find-a-doctor {
  .listbox {
    max-width: 860px;
  }
  .alphasort ul {
    padding-left: 0;
    border-left: none;
  }

  .info-boxes__wrapper {
    margin: 0 auto;
  }
}