html[data-breakpoint-helper="true"] body {
  //Enable breakpoint indicators for debugging
  &:after {
    bottom: 0;
    right: 0;
    position: fixed;
    white-space: nowrap;
    padding: 1rem;
    z-index: 10000;
    border-top-left-radius: 1rem;

    content: "--XS";
    background-color: red;
    color: black;

    @include media-breakpoint-up(sm) {
      content: "--SM";
      background-color: rgba(orange, 0.75);
    }
    @include media-breakpoint-up(md) {
      content: "--MD";
      background-color: rgba(yellow, 0.75);
    }
    @include media-breakpoint-up(lg) {
      color: white;
      content: "--LG";
      background-color: rgba(green, 0.75);
    }
    @include media-breakpoint-up(xl) {
      content: "--XL";
      background-color: rgba(blue, 0.75);
    }
    @include media-breakpoint-up(xxl) {
      content: "--XXL";
      background-color: rgba(purple, 0.75);
    }
  }
}
