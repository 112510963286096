.form {
  max-width: 860px;
}

.form-control,
.form-select {
  border-radius: 0.25rem;
  border-color: var(--neutral--500);
  &:focus {
    @include global-focus-ring;
    border-color: var(--neutral--500);
  }
}

.form-check {
  input,
  label {
    cursor: pointer;
  }
}

.form-label {
  font-weight: 700;
}

.form-field,
.ktc-default-section {
  margin-bottom: 1.5rem;
}

form[action*="/Kentico.Components/"]:not([data-hook]) {
  max-width: 860px;
  input[type="submit"] {
    @extend .btn;
    @extend .btn-blue--700;
  }

  // p.invalid-feedback {
  //   display: none !important;
  // }
}

.control-label,
.form-field legend {
  @extend .form-label;
}

.ktc-radio,
.ktc-checkbox {
  &:not(.ktc-radio-list, .ktc-checkbox-list) {
    @extend .form-check;
  }

  input {
    @extend .form-check-input;
    padding: 0;
  }

  label {
    @extend .form-check-label;
  }
}

.explanation-text {
  @extend .form-text;
}

.field-validation-error {
  display: block;
  @extend .invalid-feedback;
}

.field-validation-valid {
  @extend .valid-feedback;
}

.js-remove {
  display: none !important;
}

.form-error-group {
  border-radius: .5rem;
  border: 1px solid var(--red--500);
  padding: 1rem;
  margin-bottom: 1.5rem;
  color: var(--red--500);
  a {
    color: var(--red--500);
  }
  h2 {
    margin-bottom: 0.5rem !important;
  }
  p {
    font-weight: 500;
  }
  ol li::marker {
    color: var(--red--500);
  }
}
