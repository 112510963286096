.subpage {
  padding-top: 0px;
  margin-bottom: 3rem;
  > article {
    padding-top: 1rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-areas:
      "header"
      "content";
    grid-template-columns: minmax(0, 1fr);
    column-gap: 2rem;
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
    }
  }

  h2,
  h3 {
    margin-bottom: 1.5rem;
  }
  // LEFT RAIL VARIATION
  //=============================================
  .subpage__left-rail {
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      grid-template-areas:
        "header header"
        "aside content";
      grid-template-columns: 33% minmax(0, 1fr);
      // minmax(0, 1fr) is to combat a swiper issue.
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 25% minmax(0, 1fr);
      // minmax(0, 1fr) is to combat a swiper issue.
    }

    .subpage__content .container,
    .subpage__header-cta-wrapper {
      @include global-reset-container;
    }
  }

  // NO RAIL VARIATION
  //=============================================
  .subpage__no-rail {
  }
}

// COMPONENTS
//=============================================
.subpage__header-cta-wrapper {
  grid-area: header;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.subpage__cta {
  display: none;
  min-width: 250px;
  text-align: right;
  @include media-breakpoint-up(md) {
    display: block;
  }
  ul {
    justify-content: flex-end;
  }
  .btn {
    margin-bottom: 1rem;
  }
}

.subpage__content {
  grid-area: content;
  padding-bottom: 3rem;

  li {
    margin-bottom: 0.25rem;
  }
}

.subpage__aside {
  grid-area: aside;
  display: none;
  position: relative;
  padding-bottom: 3rem;

  @include media-breakpoint-up(md) {
    display: block;
    border-right: 1px solid var(--neutral--300);
  }
}

// TEMPLATE HUB
//=============================================
.template-hub,
.template-find-a-doctor {
  article {
    grid-template-areas:
      "header"
      "hero"
      "content";
  }
  .hub-hero {
    grid-area: hero;
  }
}
