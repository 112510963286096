.marketing-floating-cta {
  position: fixed;
  top: 300px;
  transform: translateY(-50%);
  right: -400px;

  display: flex;
  align-items: stretch;
  flex-direction: row;
  z-index: 10000;

  transition: right .3s ease-in-out; 

  @include media-breakpoint-up(lg) {
    right: -600px;
  }

  &__drawer {
    background: var(--neutral--100);
    width: 300px;
    padding: 2rem;
    border-top: 2px solid var(--blue--700);
    border-bottom: 2px solid var(--blue--700);
    height: 175px;
    > * {
      opacity: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 600px;
    }
  }

  &__side-button {
    padding: .25rem;
    background: var(--blue--700);
    color: var(--neutral--100);
    fill: var(--neutral--100);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: .8rem;
    width: 2.5rem;
    min-height: 200px;

    &:hover,
    &:focus {
      background: var(--orange--900);
    }
    
    span {
      position: absolute;
      bottom: 0rem;
      left: .5rem;
      display: inline-block;
      transform: rotate(-90deg);
      transform-origin: top left;
      white-space: nowrap;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .1rem;
      margin-right: 2rem;
    }
    svg {
      position: absolute;
      top: 1rem;
      left: .75rem;
      transform: rotate(180deg);
    }
  }


  &__bottom-button {
    position: fixed;
    font-size: 1.75rem;
    bottom: 2rem;
    right: 2rem;
    height: 4rem;
    width: 4rem;
    border-radius: 4rem;
    background: var(--blue--700);
    color: var(--neutral--100);
    fill: var(--neutral--100);

    @include media-breakpoint-up(lg) {
      display: none;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }


  &.is-open {
    right: 0;

    .marketing-floating-cta__drawer {
      height: auto;
      > * {
        opacity: 1;
      }
    }

    svg {
      transform: rotate(0deg);
    }
  }

  
}