.microsite__main-nav {
  padding-bottom: 2rem;
  border-bottom: 6px solid var(--ms-nav-active-background);
  display: none;
  @include media-breakpoint-up(md) {
    display: block !important;
    padding-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;

    @include ios-13-below() {
      margin: 0.25rem;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  li {
    flex-grow: 1;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 100%;
    padding: 0.75rem;
    text-align: center;
    text-decoration: none;
    border-radius: 0.5rem;

    background-color: var(--ms-nav-background);
    color: var(--ms-nav-color);

    &:focus,
    &:hover {
      background-color: var(--ms-nav-hover-background);
      color: var(--ms-nav-hover-color);
    }

    &:focus-visible {
      outline-color: var(--neutral--100);
      @include global-focus-ring(var(--ms-nav-active-background));
    }

    &[aria-current="page"] {
      background-color: var(--ms-nav-active-background);
      color: var(--ms-nav-active-color);
    }

    @include media-breakpoint-up(md) {
      font-size: 0.9rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
      padding: 1rem 0.75rem;
    }
  }
}

.microsite__mobile-nav {
  display: none;
  overflow-y: auto;
  padding-bottom: 3rem;
  height: calc(100vh - var(--ms-offset) - 45px);

  --list-depth-offset: 1rem;
  --dropdown-width: 40px;

  .subpage__aside-nav a {
    font-size: 1rem;
  }
}
