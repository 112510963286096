.page-blurb {
  overflow: hidden;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  img {
    float: right;
    margin-left: 1rem;
    max-width: 50%;
  }
}
