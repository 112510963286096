.alert {
  padding: 0.75rem 0;
  background-color: var(--orange--500);
  color: var(--blue--900);
  fill: var(--blue--900);

  &-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @include ios-13-below() {
      > * {
        margin-left: .5rem;
        margin-right: .5rem;
      }
    }
  }
  a {
    color: var(--blue--900);
    text-decoration: underline;
  }
  p {
    margin-bottom: 0 !important;
  }
}
