.marketing-testimonials {
  color: var(--has-color);
  h2,
  a {
    color: var(--has-color);
  }
  h2 {
    margin-bottom: 2rem;
    text-align: center;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0 4rem;
    text-align: center;
    font-size: 1.5rem;

    img {
      height: 215px;
      width: 215px;
      object-fit: cover;
      object-position: center;
    }

    a {
      font-weight: 700;
    }

    @include ios-13-below {
      > * {
        margin: 1rem;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-start;
      padding: 0 6rem;
      text-align: left;
    }
  }

  .swiper-wrapper {
    margin-bottom: 2rem;
  }
}
