.search {
  margin-bottom: 2rem;
  max-width: 860px;

  legend {
    @extend .like-h2;
  }

  label {
    @extend .like-h4;
  }

  input {
    margin-bottom: 1rem;
  }

  &__input-wrap {
    position: relative;
    margin-bottom: 1.5rem !important;
  }

  .invalid-tooltip {
    position: relative;
    margin: 0 0 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  &.was-validated :invalid,
  &.was-validated :invalid {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-check {
    label.form-check-label {
      font-size: 1rem;
    }
  }
}

.search-results {
  li {
    display: grid;
    column-gap: 1rem;
    row-gap: 0.25rem;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "content content"
      "rating link";
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--neutral--300);

    &:last-child {
      margin-bottom: 2.5rem;
      border-bottom: none;
    }
  }

  &__content {
    > a {
      font-weight: 700;
    }
  }

  &__rating {
    width: 100px;
  }

  &__image {
    grid-area: image;
  }

  &__content {
    align-self: center;
    grid-area: content;
  }

  &__rating {
    align-self: center;
    grid-area: rating;
  }

  &__link {
    align-self: center;
    grid-area: link;
  }

  li[data-has-image="true"] {
    grid-template-areas:
      "image content"
      "rating link";
  }
}
