@mixin headings {
  font-family: $font-family-headings;
  line-height: 1.3;
  text-transform: none;
  font-weight: 500;
}

h1,
.like-h1,
.fr-view h1 {
  @include headings;
  font-size: 2rem;
  font-weight: 400;
  @include media-breakpoint-up(md) {
    font-size: 2.625rem;
  }
}

h2,
.like-h2,
.fr-view h2 {
  @include headings;
  font-size: 1.625rem;
  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
}

h3,
.like-h3,
.fr-view h3 {
  @include headings;
  font-size: 1.375rem;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

h4,
.like-h4,
.fr-view h4 {
  @include headings;
  font-size: 1.2rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

h5,
.like-h5,
.fr-view h5 {
  @include headings;
  font-size: 1.125rem;
}

h6,
.like-h6,
.fr-view h6 {
  @include headings;
  font-size: 1rem;
}


hr {
  --hr: var(--neutral--300);
  border-top: 4px solid var(--hr);
  opacity: 1;

  &.hr-short {
    width: 4rem;
  }
}

@include make-heading-rules ($app-colors);

h2, h3 {
  &:not(.mb-0) + hr:not(.hr-short) {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
}