:root {
  // General
  --ms-background: var(--blue--900);
  --ms-heading-rule: var(--orange--500);

  // Footer
  --ms-footer-background: var(--blue--700);
  --ms-footer-color: var(--neutral--100);

  // Tabs
  --ms-nav-background: var(--blue--700);
  --ms-nav-color: var(--neutral--100);
  --ms-nav-hover-background: var(--blue--900);
  --ms-nav-hover-color: var(--neutral--100);
  --ms-nav-active-background: var(--orange--500);
  --ms-nav-active-color: var(--neutral--900);

  // Registration
  --ms-registration-background: var(--orange--900);
  --ms-registration-color: var(--neutral--100);
  --ms-registration-hover-background: var(--orange--500);
  --ms-registration-hover-color: var(--neutral--900);

  // Donation
  --ms-donation-background: var(--blue--100);
  --ms-donation-color: var(--neutral--900);
  --ms-donation-border: var(--blue--700);
  --ms-donation-hover-background: var(--blue--700);
  --ms-donation-hover-color: var(--neutral--100);
  --ms-donation-hover-border: var(--blue--100);

  // Date
  --ms-date-background: var(--blue--700);
  --ms-date-color: var(--neutral--100);

  --ms-offset: 4.5rem;
}

.template-microsite {
  // Nav
  --ms-nav-background: var(--neutral--300);
  --ms-nav-color: var(--neutral--900);
  --ms-nav-hover-background: var(--purple--100);
  --ms-nav-hover-color: var(--neutral--900);
  --ms-nav-active-background: var(--purple--500);
  --ms-nav-active-color: var(--neutral--100);
}
