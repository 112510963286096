.image-slider {
  overflow: hidden;
  position: relative;
  margin-bottom: 3rem;

  .swiper-wrapper {
    z-index: 1;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .figure {
      max-width: 800px;
      // padding: 0 4rem;
    }

    figcaption {
      font-style: normal;
    }
  }
}

.image-slider__toggle {
  position: absolute;
  z-index: 1000;

  // Match the distance on swiper-buttons
  top: 10px;
  right: 10px;

  @include slider-button;

  .image-slider__pause,
  .image-slider__play {
    display: none;
  }

  &[data-state="playing"] {
    .image-slider__pause {
      display: inline-block;
    }
  }

  &[data-state="paused"] {
    .image-slider__play {
      display: inline-block;
      padding-left: 0.125rem;
    }
  }
}
