.tabs {

  --tabs-trigger-background: var(--neutral--300);
  --tabs-trigger-color: var(--neutral--900);
  --tabs-trigger-hover-background: var(--purple--100);
  --tabs-trigger-hover-color: var(--neutral--900);
  --tabs-trigger-active-background: var(--purple--500);
  --tabs-trigger-active-color: var(--neutral--100);

  margin-bottom: 3rem;

  &__list {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  &__trigger {
    background-color: var(--tabs-trigger-background);
    color: var(--tabs-trigger-color);
    z-index: 100;
    font-size: 0.9rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @include media-breakpoint-up(md) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      font-size: 1rem;
    }

    &:hover {
      background-color: var(--tabs-trigger-hover-background);
      color: var(--tabs-trigger-hover-color);
    }

    &:focus {
      box-shadow: none;
    }
    &:focus-visible {
      @include global-focus-ring(var(--tabs-trigger-active-background))
    }

    &[aria-selected="true"] {
      background-color: var(--tabs-trigger-active-background);
      color: var(--tabs-trigger-active-color);
      z-index: 200;
    }
  }

  &__panels {
    &:before {
      content: "";
      position: absolute;
      display: block;
      height: 3px;
      width: 100%;
      left: 0;
      background: var(--tabs-trigger-active-background);
    }
  }

  &__panel {
    padding: 3rem 0;

    &[aria-hidden="true"] {
      display: none;
    }
  }
}

.subpage__left-rail .tabs {
  &__panels {
    position: relative;
  }
}
