.template-microsite {
  @include media-breakpoint-down(md) {
    padding-top: var(--ms-offset);
  }

  > *,
  .hub-hero {
    margin-bottom: 1.5rem;
  }

  article.subpage__left-rail,
  article.subpage__no-rail {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 6px solid var(--ms-nav-active-background);
  }

  .microsite__cta {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.template-microsite-subpage {
  article.subpage__left-rail {
    grid-template-areas: "aside content";
  }
}

// MAIN
// =============================================
.microsite-main {
  @include media-breakpoint-down(md) {
    padding-top: var(--ms-offset);
  }
  
  // padding-bottom: 2rem; // Requested on NJHK13-3152 to be removed
  background: var(--ms-background);

  h1 {
    @extend .like-h3;
  }

  h2 {
    @extend .like-h4;
  }

  h3 {
    @extend .like-h5;
  }

  h2,
  h3 {
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 3px solid var(--ms-heading-rule);
  }
}

.microsite-content {
  // padding-bottom: 2rem;
  background-color: var(--neutral--100);
  > :last-child {
    margin-bottom: 0 !important;
  }

  .container {
    padding-right: 2rem;
    padding-left: 2rem;
    width: 100%;
  }
}

.template-event-microsite {
  h2 + hr, 
  h3 + hr {
    display: none;
  }
}
