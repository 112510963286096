// HEADER
// =============================================
.microsite-header {
    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: var(--neutral--100);
        z-index: 15000;
    }
    
    @include media-breakpoint-up(md) {
        &.cm-integrated-header {
            padding: 0;
        }
    }

    padding: 1rem 0 0;

    &__mobile-trigger {
        margin-left: 1rem;

        .microsite-header__mobile-trigger-open {
            display: inline-block;
        }

        .microsite-header__mobile-trigger-close {
            display: none;
        }

        &[aria-expanded="true"] {
            .microsite-header__mobile-trigger-open {
                display: none;
            }

            .microsite-header__mobile-trigger-close {
                display: inline-block;
            }
        }
    }

    &__logo {
        img {
            max-width: 150px;

            @include media-breakpoint-up(md) {
                max-width: 300px;
            }
        }
    }

    &__utility-nav {
        font-size: 1.125rem;
        font-weight: 600;
        display: none;

        @include media-breakpoint-up(md) {
            display: block !important;
        }
    }

    .separated-list {
        @include media-breakpoint-down(md) {
            flex-direction: column;

            li:after {
                display: none;
            }
        }
    }
}

.template-microsite .microsite-header {
    @include media-breakpoint-down(md) {
        position: relative;
    }
}
