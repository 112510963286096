.marketing-icon-list {
  h2 {
    margin-bottom: 2rem;
  }
  img {
    margin-bottom: 1rem;
  }
  .col {
    text-align: center;
    p {
      font-size: 1.25rem;
      color: var(--blue--600);
      font-weight: 600;
    }
  }
}