/* Bootstrap Overrides
=========================================================================== */
// Color system

$app-colors: (
  "neutral--900": #191919,
  "neutral--700": #303030,
  "neutral--500": #5e5e5e,
  "neutral--400": #a4a7a9,
  "neutral--300": #dadddf,
  "neutral--200": #f3f3f3,
  "neutral--100": #ffffff,

  "blue--900": #00263f,
  "blue--800": #003399,
  "blue--700": #03559e,
  "blue--600": #007cc2,
  "blue--100": #edf4fa,

  "orange--900": #c45500,
  "orange--500": #f89a2e, //#f68a33 requested change from client
  "orange--100": #fde1c1,

  "purple--500": #773064,
  "purple--100": #d7bfce,

  "green--500": #038038,

  "red--500": #a71d1b,
  "red--200": #a94442,
  "red--100": #f2dede,
);

$primary: map_get($app-colors, "blue--700");
$secondary: map_get($app-colors, "orange--900");

// Approved Backgrounds
$backgrounds: (
  // "neutral--300": map_get($app-colors, "neutral--300"),
  // "neutral--300": map_get($app-colors, "neutral--300"),
);

// $theme-colors: (
//   "primary": map_get($app-colors, "blue--700"),
//   "secondary": map_get($app-colors, "orange--900"),
//   "success": map_get($app-colors, "green--500"),
//   "info": map_get($app-colors, "orange--100"),
//   "warning": map_get($app-colors, "orange--500"),
//   "danger": map_get($app-colors, "red--500"),
//   "light": map_get($app-colors, "neutral--300"),
//   "dark": map_get($app-colors, "neutral--700"),
// );

// Options
$enable-smooth-scroll: false;
$enable-transitions: false;

// Typography
$font-family-sans-serif: "Open Sans", "Segoe UI", Tahoma, sans-serif;
$font-family-headings: "Roboto", Arial, Helvetica, sans-serif;

$body-color: map_get($app-colors, "neutral--900");

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.3;
$h3-font-size: $font-size-base * 1.1;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

// Grid containers
$container-max-widths: (
  sm: 650px,
  md: 780px,
  lg: 970px,
  xl: 1440px,
  xxl: 1500px,
);

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;

// Components
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

// Buttons
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// Forms
$input-border-color: map_get($app-colors, "neutral--700");
$form-check-input-border: 1px solid map_get($app-colors, "neutral--700");
$error-color: map_get($app-colors, "red--error");
$legend-font-size: inherit;

// Modal
$modal-backdrop-bg: map_get($app-colors, "neutral--700");
$modal-backdrop-opacity: 0.85;
$modal-content-border-width: 0;

/* Custom Variables
=========================================================================== */
$font-family-serif: "Eczar", serif;

// Borders
$border: rgba(map_get($app-colors, "neutral--700"), 0.2);

$figure-caption-color: var(--has-color);


// Progress override
$progress-height: .5rem;