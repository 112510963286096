.routing-cards {
  .routing-card {
    position: relative;
    padding: 0;
    margin: 0;
    img {
      margin-bottom: 1rem;
      width: 100%;
    }
    h3 {
      margin-bottom: 0.5rem;
    }
  }
}
