// Imported directly from NJH
#screening_qualifier {
  width: 100%;
  background: #f5f5f6;
  margin: 50px 0;
  -webkit-box-shadow: 0 0 20px rgba(71, 71, 71, 0.15);
  box-shadow: 0 0 20px rgba(71, 71, 71, 0.15);
  z-index: 1;
  -webkit-box-shadow: border-box;
  box-shadow: border-box;
  position: relative;
  color: #474747;
}
#screening_qualifier * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#screening_qualifier *[tabindex] {
  outline: none;
}
#screening_qualifier nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#screening_qualifier nav div {
  border-radius: 50% 50% 50% 0;
  background: transparent;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  position: relative;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
#screening_qualifier nav div:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1a7dc1;
  -webkit-transition: inherit;
  transition: inherit;
}
#screening_qualifier nav div:not(.disabled):not(.active):hover:after,
#screening_qualifier nav div:not(.disabled):not(.active):focus:after {
  background-color: #868785;
}
#screening_qualifier nav div.disabled {
  pointer-events: none;
}
#screening_qualifier nav div.disabled:after {
  background-color: #dadade;
}
#screening_qualifier nav div.active {
  background: #1a7dc1;
}
#screening_qualifier nav div.active:after {
  background-color: #f5f5f6;
}
#screening_qualifier nav div.active:hover,
#screening_qualifier nav div.active:focus {
  background-color: #868785;
}
#screening_qualifier main {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 30px;
}
#screening_qualifier main section {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 100%;
  padding-left: 40px;
  min-height: 450px;
  overflow-y: auto;
}
#screening_qualifier main section.quiz-enter-active {
  -webkit-transition: all 0.4s ease-in-out 0.5s;
  transition: all 0.4s ease-in-out 0.5s;
  height: auto;
}
#screening_qualifier main section.quiz-leave-active {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  height: auto;
}
#screening_qualifier main section.quiz-enter {
  opacity: 0;
  -webkit-transform: scale(0.6, 0.6);
  transform: scale(0.6, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 0;
}
#screening_qualifier main section.quiz-leave-to {
  opacity: 0;
  -webkit-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
#screening_qualifier main section > div {
  width: 750px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto 0;
}
#screening_qualifier h1 {
  font-size: 40px;
  text-align: center;
  margin: 0 0 20px;
  max-width: 650px;
  line-height: 1.5;
  color: #000000;
}
#screening_qualifier main section h2 {
  font-weight: 700;
  text-align: center;
  margin: 0 0 20px;
  max-width: 650px;
  line-height: 1.5;
  color: #1a7dc1;
}
#screening_qualifier main section h2 {
  font-size: 24px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section h2 {
    font-size: calc(24px + 12 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section h2 {
    font-size: 36px;
  }
}
#screening_qualifier main section h3 {
  font-weight: 700;
  text-align: center;
  margin: 0 0 20px;
  line-height: 1.25;
  color: #1a7dc1;
}
#screening_qualifier main section h3 {
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section h3 {
    font-size: calc(20px + 6 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section h3 {
    font-size: 26px;
  }
}
#screening_qualifier main section p {
  line-height: 1.5;
  text-align: center;
  font-weight: 300;
  margin: 0 0 20px;
}
#screening_qualifier main section p {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section p {
    font-size: calc(14px + 4 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section p {
    font-size: 18px;
  }
}
#screening_qualifier main section p strong {
  display: block;
}
#screening_qualifier main section input,
#screening_qualifier main section .select {
  border-radius: 30px;
  width: 200px;
  color: #fff;
}
#screening_qualifier main section input ~ .button,
#screening_qualifier main section input ~ .formButton,
#screening_qualifier main section .select ~ .button,
#screening_qualifier main section .select ~ .formButton {
  background: #868785;
}
#screening_qualifier main section input ~ .button:not(.disabled):hover,
#screening_qualifier main section input ~ .button:not(.disabled):focus,
#screening_qualifier main section input ~ .formButton:not(.disabled):hover,
#screening_qualifier main section input ~ .formButton:not(.disabled):focus,
#screening_qualifier main section .select ~ .button:not(.disabled):hover,
#screening_qualifier main section .select ~ .button:not(.disabled):focus,
#screening_qualifier main section .select ~ .formButton:not(.disabled):hover,
#screening_qualifier main section .select ~ .formButton:not(.disabled):focus {
  background: transparent;
  border-color: #868785;
  color: #868785;
}
#screening_qualifier main section input,
#screening_qualifier main section select {
  background: #1a7dc1;
  text-align: center;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  -webkit-box-shadow: inset 0 0 10px rgba(71, 71, 71, 0.25);
  box-shadow: inset 0 0 10px rgba(71, 71, 71, 0.25);
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  font-style: normal;
}
#screening_qualifier main section input,
#screening_qualifier main section select {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section input,
  #screening_qualifier main section select {
    font-size: calc(16px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section input,
  #screening_qualifier main section select {
    font-size: 18px;
  }
}
#screening_qualifier main section input:focus,
#screening_qualifier main section select:focus {
  background: #2b98e3;
}
#screening_qualifier main section input {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px;
  margin: 0 0 20px;
}
#screening_qualifier main section input::-ms-clear,
#screening_qualifier main section input::-ms-reveal {
  display: none;
}
#screening_qualifier main section input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
}
#screening_qualifier main section input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
}
#screening_qualifier main section input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
}
#screening_qualifier main section input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
}
#screening_qualifier
  main
  section
  input[type="number"]::-webkit-inner-spin-button,
#screening_qualifier
  main
  section
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#screening_qualifier main section input[type="number"] {
  -moz-appearance: textfield;
}
#screening_qualifier main section .select {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 20px;
  border: none;
  position: relative;
  overflow: hidden;
}
#screening_qualifier main section .select:after {
  content: "";
  height: 10px;
  width: 10px;
  border-top: 2px solid currentColor;
  border-left: 2px solid currentColor;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto 0;
  pointer-events: none;
}
#screening_qualifier main section .select select {
  position: absolute;
  padding-left: 20px;
  text-align: left;
  border-radius: inherit;
  top: 0;
  color: inherit;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
#screening_qualifier main section .select select::-ms-value {
  background: transparent;
}
#screening_qualifier main section .select select::-ms-expand {
  display: none;
}
#screening_qualifier main section .select select option {
  color: #474747;
  background: #fff;
  border: none !important;
  outline: none !important;
  margin: none !important;
}
#screening_qualifier main section .button,
#screening_qualifier main section .formButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  height: 60px;
  border-radius: 30px;
  padding: 0 30px;
  background: #1a7dc1;
  margin: 0 0 10px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  min-width: 200px;
  border: 4px solid transparent;
  font-weight: 700;
}
#screening_qualifier main section .button,
#screening_qualifier main section .formButton {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section .button,
  #screening_qualifier main section .formButton {
    font-size: calc(14px + 4 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section .button,
  #screening_qualifier main section .formButton {
    font-size: 18px;
  }
}
#screening_qualifier main section .button:not(.disabled):hover,
#screening_qualifier main section .button:not(.disabled):focus,
#screening_qualifier main section .formButton:not(.disabled):hover,
#screening_qualifier main section .formButton:not(.disabled):focus {
  background: transparent;
  border-color: #1a7dc1;
  color: #1a7dc1;
}
#screening_qualifier main section .button.disabled,
#screening_qualifier main section .formButton.disabled {
  pointer-events: none;
  background: #e6e6e6;
  color: #a6a6a6;
}
#screening_qualifier main section .button.disabled:focus,
#screening_qualifier main section .button.disabled:hover,
#screening_qualifier main section .formButton.disabled:focus,
#screening_qualifier main section .formButton.disabled:hover {
  border-color: #ccc;
}
#screening_qualifier main section .button ~ .button,
#screening_qualifier main section .button ~ .formButton,
#screening_qualifier main section .formButton ~ .button,
#screening_qualifier main section .formButton ~ .formButton {
  background: #868785;
}
#screening_qualifier main section .button ~ .button:not(.disabled):hover,
#screening_qualifier main section .button ~ .button:not(.disabled):focus,
#screening_qualifier main section .button ~ .formButton:not(.disabled):hover,
#screening_qualifier main section .button ~ .formButton:not(.disabled):focus,
#screening_qualifier main section .formButton ~ .button:not(.disabled):hover,
#screening_qualifier main section .formButton ~ .button:not(.disabled):focus,
#screening_qualifier
  main
  section
  .formButton
  ~ .formButton:not(.disabled):hover,
#screening_qualifier
  main
  section
  .formButton
  ~ .formButton:not(.disabled):focus {
  background: transparent;
  border-color: #868785;
  color: #868785;
}
#screening_qualifier main section .back {
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #474747;
  margin: 10px;
}
#screening_qualifier main section .back {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section .back {
    font-size: calc(14px + 4 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section .back {
    font-size: 18px;
  }
}
#screening_qualifier main section .back:before {
  content: "";
  height: 10px;
  width: 10px;
  border-top: 2px solid currentColor;
  border-left: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: block;
  margin-right: 5px;
}
#screening_qualifier main section .back:hover,
#screening_qualifier main section .back:focus {
  text-decoration: underline;
  color: #1a7dc1;
}
#screening_qualifier main section.result {
  padding-left: 0;
}
#screening_qualifier main section.result table {
  width: 100%;
}
#screening_qualifier main section.result > div {
  width: 1300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media (min-width: 800px) {
  #screening_qualifier main section.result > div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
}
#screening_qualifier main section.result > div * {
  text-align: left;
}
#screening_qualifier main section.result > div h3 {
  padding: 0 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  text-align: center;
  width: 100%;
}
#screening_qualifier
  main
  section.result
  > div
  h3:first-of-type:not(:only-of-type) {
  margin-bottom: 5px;
}
#screening_qualifier
  main
  section.result
  > div
  h3:last-of-type:not(:only-of-type) {
  color: #474747;
  margin-bottom: 30px;
}
#screening_qualifier
  main
  section.result
  > div
  h3:last-of-type:not(:only-of-type) {
  font-size: 17px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier
    main
    section.result
    > div
    h3:last-of-type:not(:only-of-type) {
    font-size: calc(17px + 3 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier
    main
    section.result
    > div
    h3:last-of-type:not(:only-of-type) {
    font-size: 20px;
  }
}
#screening_qualifier main section.result > div h4 {
  margin-bottom: 20px;
}
#screening_qualifier main section.result > div h4 {
  font-size: 18px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section.result > div h4 {
    font-size: calc(18px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section.result > div h4 {
    font-size: 20px;
  }
}
#screening_qualifier main section.result > div h4 em {
  margin-left: 5px;
  color: #474747;
}
#screening_qualifier main section.result > div h4 em {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section.result > div h4 em {
    font-size: calc(16px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section.result > div h4 em {
    font-size: 18px;
  }
}
#screening_qualifier main section.result > div h4.stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
#screening_qualifier main section.result > div h4.stack em {
  margin-left: 20px;
}
#screening_qualifier main section.result > div .aside {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
@media (min-width: 800px) {
  #screening_qualifier main section.result > div .aside {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;
  }
}
#screening_qualifier main section.result > div p {
  font-size: 14px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section.result > div p {
    font-size: calc(14px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section.result > div p {
    font-size: 16px;
  }
}
#screening_qualifier main section.result > div p + ul {
  margin-top: -15px;
}
#screening_qualifier main section.result > div p.call {
  margin-top: -15px;
  margin-left: 17.5px;
  padding-left: 7.5px;
  border-left: 3px solid #1a7dc1;
}
#screening_qualifier main section.result > div ul {
  padding: 0;
  margin: 0;
  margin-left: 20px;
  list-style: none;
  margin-bottom: 20px;
}
#screening_qualifier main section.result > div ul li {
  position: relative;
  margin-bottom: 5px;
}
#screening_qualifier main section.result > div ul li {
  font-size: 13px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section.result > div ul li {
    font-size: calc(13px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section.result > div ul li {
    font-size: 15px;
  }
}
#screening_qualifier main section.result > div ul li:last-child {
  margin-bottom: 0;
}
#screening_qualifier main section.result > div ul li.head {
  list-style: none;
  margin-left: -20px;
  padding: 0;
}
#screening_qualifier main section.result > div ul li.head:before {
  display: none;
}
#screening_qualifier main section.result > div ul li:before {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #1a7dc1;
  content: "";
  display: block;
  position: absolute;
  left: -10px;
  top: 8px;
}
#screening_qualifier main section.result > div .callout {
  padding: 10px;
  border-left: 8px solid #1a7dc1;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 15px rgba(71, 71, 71, 0.25);
  box-shadow: 0 0 15px rgba(71, 71, 71, 0.25);
}
#screening_qualifier main section.result > div .callout p {
  font-style: bold;
}
#screening_qualifier main section.result > div .callout ul {
  margin-bottom: 0;
}
#screening_qualifier main section.result > div small {
  display: block;
  margin-bottom: 20px;
  color: #868785;
}
#screening_qualifier main section.result > div small {
  font-size: 12px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main section.result > div small {
    font-size: calc(12px + 1 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main section.result > div small {
    font-size: 13px;
  }
}
#screening_qualifier main section.result > div small:before {
  content: "*";
  margin-left: -7px;
  margin-right: 2px;
}
#screening_qualifier main section.result > div .button,
#screening_qualifier main section.result > div .formButton {
  margin: 0 auto 20px;
}
#screening_qualifier main .screeningForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
#screening_qualifier main .screeningForm .screeningForm-info {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
#screening_qualifier main .screeningForm .screeningForm-info p {
  text-align: left;
}
#screening_qualifier main .screeningForm .screeningForm-body {
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
#screening_qualifier main .screeningForm .screeningForm-body table {
  width: 100%;
}
#screening_qualifier main .screeningForm .screeningForm-body p {
  text-align: left;
  margin-bottom: 10px;
}
#screening_qualifier main .screeningForm .screeningForm-body input {
  width: 100%;
  height: 45px;
  border-radius: 22.5px;
  margin-bottom: 10px;
  font-style: normal;
}
#screening_qualifier main .screeningForm .screeningForm-body input {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier main .screeningForm .screeningForm-body input {
    font-size: calc(16px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier main .screeningForm .screeningForm-body input {
    font-size: 18px;
  }
}
#screening_qualifier main .screeningForm .screeningForm-body input.is-invalid {
  width: calc(100% - 30px);
}
#screening_qualifier
  main
  .screeningForm
  .screeningForm-body
  input.is-invalid
  + .formError {
  padding-left: 22.5px;
  width: 100%;
  position: relative;
  color: transparent;
  height: 0;
  font-size: 0;
}
#screening_qualifier
  main
  .screeningForm
  .screeningForm-body
  input.is-invalid
  + .formError {
  font-size: 13px;
}
@media screen and (min-width: 320px) {
  #screening_qualifier
    main
    .screeningForm
    .screeningForm-body
    input.is-invalid
    + .formError {
    font-size: calc(13px + 2 * ((100vw - 320px) / 1080));
  }
}
@media screen and (min-width: 1400px) {
  #screening_qualifier
    main
    .screeningForm
    .screeningForm-body
    input.is-invalid
    + .formError {
    font-size: 15px;
  }
}
#screening_qualifier
  main
  .screeningForm
  .screeningForm-body
  input.is-invalid
  + .formError:after,
#screening_qualifier
  main
  .screeningForm
  .screeningForm-body
  input.is-invalid
  + .formError:before {
  position: absolute;
  content: "";
  display: block;
  height: 20px;
  width: 4px;
  background: red;
  top: -45px;
  right: 10px;
  border-radius: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#screening_qualifier
  main
  .screeningForm
  .screeningForm-body
  input.is-invalid
  + .formError:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#screening_qualifier main .screeningForm .screeningForm-body .button,
#screening_qualifier main .screeningForm .screeningForm-body .formButton {
  background: #868785;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#screening_qualifier main .screeningForm .screeningForm-body .button:hover,
#screening_qualifier main .screeningForm .screeningForm-body .button:focus,
#screening_qualifier main .screeningForm .screeningForm-body .formButton:hover,
#screening_qualifier main .screeningForm .screeningForm-body .formButton:focus {
  background: transparent;
  border-color: #868785;
  color: #868785;
}
#screening_qualifier .colorBlue {
  color: #1a7dc1;
}
#screening_qualifier .colorGray {
  color: #f5f5f6;
}
#screening_qualifier .colorDark {
  color: #868785;
}

/*# sourceMappingURL=main.css.map */
