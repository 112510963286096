// HERO
//====================================================

.marketing-hero {
  background: var(--blue--100);
  h1 {
    line-height: 1.2;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  ul {
    font-size: 1.2rem;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      svg {
        margin-top: 6px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .app-icon {
      margin-right: 0.75rem;
    }
  }

  &__content {
    @include marketing-section;

    @include media-breakpoint-up(lg) {
      padding-right: calc(var(--bs-gutter-x) * 2);
    }
    img {
      float: right;
      margin-left: 1rem;
    }
  }

  &__image {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
