@mixin make-buttons($buttons) {
  @each $key, $var in $buttons {
    .btn,
    a.btn,
    button,
    button.btn,
    input.btn,
    a span.btn {
      &.btn-#{$key} {
        background-color: map_get($var, "default");
        border-color: map_get($var, "default");
        color: map_get($var, "color");
        svg {
          fill: map_get($var, "color");
        }

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active {
          background-color: map_get($var, "hover");
          border-color: map_get($var, "hover");
          color: map_get($var, "hover-color");
          svg {
            fill: map_get($var, "hover-color");
          }
        }

        &:not(:disabled):not(.disabled):focus {
          box-shadow: 0 0 0 3px var(--neutral--100),
            0 0 0 6px map_get($var, "focus-ring");
        }
      }
    }
    a:focus-visible:not(:disabled) span.btn.btn-#{$key} {
      box-shadow: 0 0 0 3px var(--neutral--100),
        0 0 0 6px map_get($var, "focus-ring");
    }
  }
}

@mixin make-outline-buttons($buttons) {
  @each $key, $var in $buttons {
    .btn,
    a.btn,
    button,
    button.btn,
    input.btn,
    a:not(.btn) span.btn {
      &.btn-#{$key}-outline {
        background-color: transparent;
        color: map_get($var, "default");
        border-color: map_get($var, "default");
        svg {
          fill: map_get($var, "default");
        }

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active {
          border-color: map_get($var, "hover");
          color: map_get($var, "hover");
          svg {
            fill: map_get($var, "hover");
          }
        }

        &:not(:disabled):not(.disabled):focus-visible {
          box-shadow: 0 0 0 3px var(--neutral--100),
            0 0 0 6px map_get($var, "focus-ring");
        }
      }
    }

    a:focus-visible:not(:disabled) span.btn.btn-#{$key}-outline {
      box-shadow: 0 0 0 3px var(--neutral--100),
        0 0 0 6px map_get($var, "focus-ring");
    }
  }
}


@mixin slider-button {
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;

  text-align: center;
  line-height: 2.5rem;

  border-radius: 100%;
  border: 1px solid var(--blue--700);

  background: white;

  svg {
    fill: var(--blue--700) !important;
  }

  cursor: pointer;

  &:hover,
  &:focus-visible {
    --swiper-theme-color: var(--neutral--100);
    background-color: var(--blue--700);
    svg {
      fill: var(--neutral--100) !important;
    }
  }

  &:focus-visible {
    @include global-focus-ring;
  }

  &:active {
    background-color: var(--blue--900);
  }
}