.template-physician {

  .physician-description {
    img {
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }
  }

  #overview {
    dl {
      row-gap: 0;
    }
  }

  #ratings {
    .metric {
      padding-top: 1rem;
      &-rating {
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--neutral--300);
      }
    }

    .comments-list {
      li {
        margin-bottom: 1.5rem;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
