.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-columns {
  column-count: 1;
  column-gap: 1rem;

  @include media-breakpoint-up(md) {
    &.list-columns-2,
    &.list-columns-3 {
      column-count: 2;
    }
  }

  @include media-breakpoint-up(lg) {
    &.list-columns-3 {
      column-count: 3;
    }
  }
}

.separated-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  li {
    position: relative;
    padding: 0.5rem;
    &::after {
      @include global-separator;
    }
  }

  a {
    position: relative;
    text-decoration: none;
  }

  li:last-child {
    &::after {
      display: none;
    }
  }
}

.lined-list {
  margin-left: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--neutral--300);

    &:last-child {
      border-bottom: none;
    }

    a {
      text-decoration: none !important;
    }
  }
}

// Description List
dl {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
  }
}

dt {
  grid-column: 1;
}

dd {
  grid-column: 2;
}
