@mixin make-backgrounds($backgrounds) {
  @each $key, $var in $backgrounds {
    .bg-#{$key} {
      --has-background: #{$var};
      --has-color: #{color-contrast($var, map-get($app-colors, 'neutral--900'), map-get($app-colors, 'neutral--100'))};
      a {
        --has-color: #{color-contrast($var, map-get($app-colors, 'neutral--900'), map-get($app-colors, 'neutral--100'))};
      }
    }
  }
}


@mixin make-heading-rules ($colors) {
  @each $key, $var in $colors {
    .hr-#{$key} {
      --hr: var(--#{$key});
    }
  }
}

@mixin make-fill-rules ($colors) {
  @each $key, $var in $colors {
    .fill-#{$key} {
      fill: var(--#{$key});
    }
  }
}