.clinical-trials {
  &__featured {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    > * {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    &-image {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 1.5rem;

      @include media-breakpoint-up(lg) {
        padding: 3rem 2.5rem;
      }
    }
  }

  ul {
    padding-left: var(--bs-gutter-x);
    @include media-breakpoint-up(md) {
      
      .template-programs-services & {
        column-count: 2;
        column-gap: 2rem;
      }
    }
  }

}



// Progressive enhancements
.clinical-trials {
  container-name: clinicalTrials;
  container-type: inline-size;
}

@container clinicalTrials (max-width: 500px) {
  .clinical-trials__featured {
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
}
