.microsite-main {
  .btn-donation {
    background-color: var(--ms-donation-background);
    color: var(--ms-donation-color);
    border-width: 2px;
    border-color: var(--ms-donation-border);

    &:hover,
    &:focus {
      background-color: var(--ms-donation-hover-background);
      color: var(--ms-donation-hover-color);
      border-color: var(--ms-donation-hover-border);
    }

    &:focus {
      @include global-focus-ring(var(--ms-donation-hover-background));
    }
  }

  .btn-registration {
    background-color: var(--ms-registration-background);
    color: var(--ms-registration-color);

    &:hover,
    &:focus {
      background-color: var(--ms-registration-hover-background);
      color: var(--ms-registration-hover-color);
    }

    &:focus {
      @include global-focus-ring(var(--ms-registration-hover-background));
    }
  }
}
