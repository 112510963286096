.accordion {
  --accordion-padding-y: 1rem;
  --accordion-padding-x: 1rem;
  
  --accordion-background: var(--blue--600);
  --accordion-color: var(--neutral--100);
  --accordion-hover-background: var(--neutral--700);
  --accordion-hover-color: var(--neutral--100);
  --accordion-active-background: var(--orange--500);
  --accordion-active-color: var(--neutral--900);
  
  --accordion-border-radius: 0.35rem;
  --accordion-border-color: var(--neutral--300);

  border: 1px solid var(--accordion-border-color);
  border-radius: calc(var(--accordion-border-radius) + 1px);
  margin-bottom: 2rem;
}

.accordion__trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;
  padding: var(--accordion-padding-y) var(--accordion-padding-x);

  background: var(--accordion-background);
  color: var(--accordion-color);
  fill: var(--accordion-color);

  font-weight: 700;
  text-align: left;
  line-height: 1.1;

  border-radius: 0;
  border-bottom: 1px solid var(--accordion-border-color);

  z-index: 100;

  &-minus {
    display: none;
  }

  &:hover,
  &:focus {
    background: var(--accordion-hover-background);
    color: var(--accordion-hover-color);
  }
  &:focus {
    box-shadow: none;
  }
  &:focus-visible {
    z-index: 200;
    @include global-focus-ring(var(--accordion-active-background));
  }

  &[aria-expanded="true"] {
    background: var(--accordion-active-background);
    color: var(--accordion-active-color);

    &:focus-visible {
      z-index: 200;
      @include global-focus-ring(var(--neutral--900));
    }

    .accordion__trigger-plus {
      display: none;
    }
    .accordion__trigger-minus {
      display: block;
    }
  }
}

.accordion__panel {
  overflow: hidden;
  padding: var(--accordion-padding-y) var(--accordion-padding-x);
  border-bottom: 1px solid var(--neutral--300);
  &[aria-hidden="true"] {
    display: none;
  }

  img[style*="float: left"],
  img[style*="float:left"] {
    margin-right: 1rem;
  }
  img[style*="float: right"],
  img[style*="float:right"] {
    margin-left: 1rem;
  }
}

.accordion__item {
  &:first-child {
    .accordion__trigger {
      border-top-left-radius: var(--accordion-border-radius) !important;
      border-top-right-radius: var(--accordion-border-radius) !important;
    }
  }
  &:last-child {
    .accordion__trigger {
      border-bottom-left-radius: var(--accordion-border-radius);
      border-bottom-right-radius: var(--accordion-border-radius);
      border-bottom: none;
      &[aria-expanded="true"] {
        border-radius: 0;
      }
    }
    .accordion__panel {
      border-bottom-left-radius: var(--accordion-border-radius);
      border-bottom-right-radius: var(--accordion-border-radius);
      border-bottom: none;
    }
  }
}

.accordion-neutral {
  --accordion-background: var(--neutral--100);
  --accordion-color: var(--neutral--900);

  .accordion__trigger[aria-expanded="true"] {
    --accordion-background: var(--neutral--300);
    --accordion-color: var(--neutral--900);
  }
}
