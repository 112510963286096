:root {
  @each $key, $var in $app-colors {
    --#{$key}: #{$var};
  }

  --header-height: 81px;
  --has-color: var(--neutral--900);
  --has-backround: var(--neutral--100);
}

a.skip-to-content {
  background-color: var(--blue--900);
  color: var(--neutral--100);
  display: block;
  left: 0;
  padding: $spacer;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: underline;
  top: 0;
  width: 100%;
  &:focus {
  }
  &:hover {
    color: var(--neutral--100);
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

body.remove-scrollbar {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  main {
    display: none;
  }
}

a {
  --has-color: var(--blue--600);
  color: var(--has-color);
}
