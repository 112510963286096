.app-header {
  background-color: var(--neutral--100);
  position: relative;
  z-index: 2000;

  a {
    text-decoration: none;
  }

  // Reset all uls
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .hide-separator {
      a::after {
        display: none;
      }
    }
  }

  &.has-search-open {
    @include media-breakpoint-up(md) {
      background-color: var(--blue--100);
    }
  }
}

// Using grid so that tab focus order selects the logo/home link first, than the utility nav.
.app-header__large-screen-nav-panel > .container {
  display: grid;
  grid-template-areas:
    "utility utility"
    "logo quick";
  grid-template-columns: 150px 1fr;
  grid-template-rows: repeat(2, auto);
  column-gap: 1rem;
  justify-items: end;
  align-items: center;
  grid-template-columns: 300px 1fr;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

// LOGO
// ========================================================================

.app-header__logo {
  flex-grow: 1;

  @include media-breakpoint-up(md) {
    grid-area: logo;
    justify-self: start;
  }

  a {
    display: block;
    outline: transparent;
    border-radius: 0.5rem;
    &:focus {
      box-shadow: 0 0 0 3px var(--neutral--100), 0 0 0 6px var(--blue--700);
    }
  }

  img {
    width: 150px;
    @include media-breakpoint-up(md) {
      width: 250px;
    }

    @include media-breakpoint-up(lg) {
      width: 300px;
    }
  }
}

// UTILITY NAVIGATION
// ========================================================================

.app-header__utility-nav {
  @include media-breakpoint-up(md) {
    grid-area: utility;
  }

  li {
    position: relative;
    &::after {
      @include global-separator;
    }
  }

  a:not(.btn) {
    display: block;
    padding: 0.5rem;
    position: relative;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  li:last-child {
    &::after {
      display: none;
    }
    a:not(.btn) {
      padding-right: 0;
    }
  }
}

// QUICK NAVIGATION
// ========================================================================

.app-header__quick-nav {
  grid-area: quick;
  align-self: center;
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    width: auto;
    margin-bottom: 0;
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    width: 100%;
  }

  li {
    flex-basis: 50%;
    @include media-breakpoint-up(md) {
      flex-basis: auto;
    }
  }

  a.btn.btn-sm {
    display: block;
    width: 100%;
    text-align: center;
    // Dropping down to not mess with default btn styles on md and up.
    @include media-breakpoint-down(md) {
      // reduce padding to leave run for longish text.
      padding-left: 0.125rem;
      padding-right: 0.125rem;
    }
  }
}

// SEARCH BUTTON
// ========================================================================

a.btn.app-header__circle-button,
button.btn.app-header__circle-button {
  appearance: none;
  padding: 0;
  font-size: 1.5rem;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  color: var(--blue--700);
  svg {
    fill: var(--blue--700);
  }
  &:focus {
    @include global-focus-ring;
  }
}

// PRIMARY NAVIGATION DESKTOP
// ========================================================================

.app-header__large-screen-nav-panel {
  --border-height: 5px;
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
  .app-header__primary-nav {
    z-index: 1200;
    background-color: var(--blue--700);
    width: 100%;
    color: white;
    padding-top: 0.25rem;
    border-bottom: var(--border-height) solid var(--orange--500);
  }

  .app-header__primary-nav-list {
    align-items: stretch;

    > li {
      flex-basis: 16.66666%;
      flex-grow: 1;
    }
  }

  .app-header__primary-nav-item {
    position: relative;

    &:focus-within {
      z-index: 100;
    }

    &::after {
      @include global-separator;
      opacity: 0.5;
    }

    button,
    > a {
      color: var(--neutral--100);
      display: block;
      font-size: 0.9rem;
      font-weight: 500;
      font-family: $font-family-headings;
      line-height: 1;
      word-wrap: normal;
      white-space: normal;
      width: auto;
      padding: 0.75rem 0.25rem;
      margin: 0;
      border-radius: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus,
      &:hover {
        background-color: var(--blue--600);
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        padding: 0.75rem 0.5rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
      }
    }

    .app-header__primary-nav-item-panel {
      display: none;
      position: absolute;
      background: var(--neutral--100);
      top: calc(100% + var(--border-height));
      padding: 1rem;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      width: auto;
      &-row {
        display: grid;
        grid-template-columns: repeat(2, auto);
        margin-bottom: 2rem;
      }

      &-column {
        width: 300px;
      }
      ul {
        flex-direction: column;
        li:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
      a {
        color: var(--blue--700);
        font-size: 1.125rem;
      }

      > a {
        font-weight: bold;
        border-bottom: 2px solid var(--orange--500);
      }
    }

    &.is-current {
      button {
        background-color: var(--blue--900);
        color: var(--neutral--100);
      }
    }

    &.is-open {
      &:after {
        display: none;
      }

      button {
        background-color: var(--orange--500);
        color: var(--neutral--900);
      }

      .app-header__primary-nav-item-panel {
        display: block;
      }
    }

    // Middle children center the panel
    &:nth-last-child(3),
    &:nth-last-child(4) {
      &:not(:nth-child(1), :nth-child(2)) {
        .app-header__primary-nav-item-panel {
          right: 50%;
          translate: 50% 0;
        }
      }
    }
    // Last and second child swap the menu to the other side.
    &:nth-last-child(1),
    &:nth-last-child(2) {
      &:not(:nth-child(1), :nth-child(2)) {
        .app-header__primary-nav-item-panel {
          right: 0;
        }
      }
    }

    &:last-child::after {
      display: none;
    }
  }
}

// I WANT TO...
// ========================================================================
.app-header__want-to {
  --want-to-width: 100%;
  position: relative;
  z-index: 1500;
  width: auto;
  flex-grow: 1;

  @include media-breakpoint-up(md) {
    --want-to-width: 350px;
    flex-grow: 0;
  }

  button {
    width: var(--want-to-width);
    display: block;
    border: 1px solid var(--neutral--500);
    text-align: left;
    padding: 0.375rem 0rem 0.375rem 1rem;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    background-color: var(--neutral--100);
    &:focus {
      @include global-focus-ring;
    }
    &:after {
      content: "▼";
      font-size: 0.8rem;
      display: block;
      height: 100%;
      padding: 0.65rem 1rem;
      background-color: var(--orange--900);
      color: white;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-wrapper {
    display: none;
    position: absolute;
    width: var(--want-to-width);
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid var(--neutral--300);
    border-top: none;
    background-color: var(--neutral--100);
    &.is-open {
      display: block;
    }
    ul {
      flex-direction: column;
    }
    a {
      display: block;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      color: var(--neutral--900);

      &:hover,
      &:focus {
        background-color: var(--blue--700);
        color: var(--neutral--100);
      }
    }
  }
}

// SEARCH MODULE
// ========================================================================

.app-header__search {
  background-color: var(--blue--100);
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .container {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include media-breakpoint-up(md) {
      padding-top: 3rem;
    }
  }

  &-close.btn-close {
    display: none;
    position: absolute;
    top: 1rem;
    right: calc(var(--bs-gutter-x) * 0.5);
    padding: 0;
    @include media-breakpoint-up(md) {
      display: block;
    }
    &:focus {
      @include global-focus-ring;
    }
  }

  &-input-wrapper {
    position: relative;
  }

  &-feedback {
    position: absolute;
    top: 100%;
  }

  &-form {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

// MOBILE OVERRIDES
// ========================================================================

.app-header__small-screen-nav-panel {
  display: block;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  @include media-breakpoint-up(md) {
    display: none;
  }

  &-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    gap: 1rem;
  }

  &-drawer {
    display: none;
  }

  .app-header__primary-nav {
    margin-bottom: 1rem;
    &-item {
      padding: 0.5rem 0;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid var(--neutral--300);

      > button {
        display: block;
        padding: 0 0 0 2rem;
        margin: 0;
        width: 100%;
        text-align: left;
        font-size: 1.125rem;
        color: var(--blue--700);
        font-weight: bold;
        position: relative;

        &::before {
          --dropdown-arrow-color: var(--blue--700);
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 0 7px 9px;
          border-color: transparent transparent transparent
            var(--dropdown-arrow-color);
          position: absolute;
          top: 50%;
          left: 0.5rem;
          translate: 0 -50%;
        }
        &:focus {
          @include global-focus-ring;
        }
      }

      a {
        display: block;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        color: var(--blue--700);
      }

      ul {
        padding-left: 1rem;
      }

      &-panel {
        display: none;
      }

      &.is-open {
        .app-header__primary-nav-item-panel {
          display: block;
        }
        > button {
          color: white;
          background: var(--blue--700);
          &::before {
            --dropdown-arrow-color: var(--neutral--100);
            rotate: 90deg;
          }
        }
      }
    }
  }

  .app-header__page-nav {
    background-color: var(--orange--500);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    &.has-open-panel {
      background-color: var(--orange--100);
    }
    .app-header__primary-nav-item {
      border-bottom: none;
      margin: 0;
      padding: 0;
      a {
        color: var(--neutral--900);
      }
      > button {
        color: var(--neutral--900);
        &::before {
          --dropdown-arrow-color: var(--neutral--900);
        }
      }

      &.is-open {
        > button {
          color: var(--neutral--900);
          background: transparent;
          &::before {
            --dropdown-arrow-color: var(--neutral--900);
          }
        }
      }
    }
  }

  // User opened the header panel
  &.is-open {
    position: fixed;
    inset: 0;
    background-color: var(--neutral--100);
    height: 100vh;
    width: 100vw;
    .app-header__small-screen-nav-panel-drawer {
      display: block;
      height: calc(100vh - var(--header-height));
      background: var(--neutral--100);
      overflow-y: auto;
      padding-top: 1rem;
      padding-bottom: 3rem;
    }
  }
}
