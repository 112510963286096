@mixin marketing-section() {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

// LAYOUT
//===============================
@import "./layout/header";
@import "./layout/main";
@import "./layout/footer";

// COMPONENTS
//===============================

@import "./components/appointment";
@import "./components/cta-list";
@import "./components/clinical-trials";
@import "./components/doctors";
@import "./components/floating-cta";
@import "./components/hero";
@import "./components/icon-list";
@import "./components/image-title";
@import "./components/living-with";
@import "./components/testimonials";
@import "./components/we-hope";
