.info-boxes {
  ul {
    list-style: none;
    padding: 0;
  }
  &__list {
    align-items: stretch;
    margin-bottom: 2rem;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    max-width: 250px;
    padding: 0.5rem;
    padding-bottom: 3rem;
    border: 1px solid var(--neutral--300);
    @include media-breakpoint-up(md) {
      &::after,
      &::before {
        z-index: -1;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &::after {
        content: "+";
        padding-right: 0.25rem;
        color: var(--neutral--100);
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
      }
      &::before {
        content: "";
        display: block;
        border-style: solid;
        width: 0;
        height: 0;
        border-width: 0 0 3rem 3rem;
        border-color: transparent transparent var(--blue--600) transparent;
      }
    }

    &.physician {
      &::after, &::before {
        display: none;
      }

      padding-bottom: 0.5rem;
    }
  }

  &__anchor,
  &__trigger {
    position: absolute;
    inset: 0;
    width: 100%;

    &:focus {
      @include global-focus-ring;
    }
  }

  &__anchor {
    display: none; // For showing client modal on mobile.
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__trigger {
    z-index: 1;
    display: block; // For showing client modal on mobile.
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__content {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--blue--600);

    img {
      margin-bottom: 0.5rem;
      width: 100%;
      height: auto;
    }
  }

  &__links {
    position: relative;
    display: flex;
    gap: 2rem;
    padding-top: 1rem;

    &::before {
      position: absolute;
      content: "";
      display: block;
      width: 4rem;
      height: 3px;
      top: 0;
      left: 0;
      background: var(--neutral--300);
    }
    .btn {
      padding: 0;
      color: var(--blue--600);
      position: relative;
      &:focus {
        @include global-focus-ring;
      }
      .info-boxes__reveal-all:after {
        position: absolute;
        content: "";
        right: -1rem;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: var(--neutral--300);
      }
    }
  }
}

.info-boxes__modal {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.info-boxes__rating,
.metric-rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;

  @include ios-13-below() {
    margin: 0.25rem;
  }
  img {
    width: 30px;
  }
}