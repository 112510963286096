.marketing-we-hope {
  text-align: center;
  &__appointment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .btn {
    @extend .btn-neutral--100-outline;
  }

  &.bg-blue--100,
  &.bg-orange--100,
  &.bg-purple--100,
  &.bg-neutral--100 {
    .btn {
      @extend .btn-neutral--900-outline;
    }
  }
}
