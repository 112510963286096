.marketing-appointment {
  clear: both;
  display: block;
  padding-top: 2rem;
  > * {
    display: block;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  &__phone {
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin-left: 1rem;
      margin-bottom: 0 !important;
    }
  }
}
