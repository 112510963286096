.microsite-date {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: var(--ms-date-background);
  color: var(--ms-date-color);

  .event {
    --ms-date-gap: 2rem;
    margin: 0;
    padding: 0;
    gap: var(--ms-date-gap);
    display: grid;
    border-bottom: none;

    @include media-breakpoint-up(md) {
      grid-template-columns: auto 1fr auto;
      --ms-date-gap: 4rem;
    }
    @include media-breakpoint-up(lg) {
      --ms-date-gap: 6rem;
    }
  }
  .event-date {
    &:after {
      right: calc(var(--ms-date-gap) / -2);
    }
  }
  .event-content {
    font-size: 1.125rem;
  }
  hr {
    border-width: 1px;
    margin: 2rem 0;
  }
  .event-cta {
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      justify-self: flex-end;
    }
  }
  a {
    color: var(--ms-date-color);

    &:hover {
      text-decoration: none;
    }
  }

  + .has-padding .col {
    padding-top: 0 !important;
  }
}
