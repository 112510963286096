// HEADER
//====================================================
.marketing-header {
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: var(--neutral--100);
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 20%);
  padding: 1rem 0 1.5rem 0;
  transition: 0.5s ease-in-out;

  svg {
    width: 180px;
    height: auto;
    transition: 0.5s ease-in-out;
    @include media-breakpoint-up(md) {
      width: 280px;
    }
  }

  &.is-scrolled {
    padding: 0.5rem 0 1rem 0;
    svg {
      width: 180px;
    }
  }

  &__cta {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    a {
      color: var(--orange--900);
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.05rem;
    }
  }
}