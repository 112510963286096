.marketing-clinical-trials {
  text-align: center;
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
  
  &__item {
    flex-direction: column;
    align-items: center;
    
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: flex-start;
    
    }
  }
}