.template-press-release {

  .subpage__left-rail {
    padding-top: 4rem;
  }

  h1 {
    line-height: 1.1;
    font-size: 2rem;
  }

  .share-story {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 3rem;
  }
}