.marketing-cta-list,
.marketing-clinical-trials {
  .container > p {
    font-size: 1.25rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    flex-grow: 0;
    align-items: flex-start;
    gap: 1rem;
    img {
      height: 150px;
      width: 150px;
      object-fit: cover;
      object-position: center;
    }
  }
}
