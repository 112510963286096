.video {
  --transcript-height: 300px;
  &__embed {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;

  }

  &__figcaption {
    text-align: right;
    padding-top: .5rem;
  }

  &__icon {
    fill: var(--orange--900);
  }

  &__trigger {
    padding: .25rem 0 !important;
    

    &[aria-expanded="true"] {
      .video__icon {
        transform: rotate(180deg);
      }
    }
  }

  &__transcript {
    margin-top: 1rem;
    text-align: left;
    max-height: var(--transcript-height);
    overflow-y: auto;
    padding: 0 1.5rem 3rem 2rem;
    @include media-breakpoint-up(lg) {
      padding: 0 3rem 3rem 3.5rem;
    }
  }

}