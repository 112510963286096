.widget-zone {
  &.has-margin {
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }
  }
  &.has-padding {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .row {
    align-items: stretch;
  }

  // article {
  //   padding-top: 0;
  //   padding-bottom: 0;
  // }
}

// If they choose a colored background in a left rail lets add some padding.
.subpage__left-rail .subpage__content .widget-zone.bg:not(.bg-neutral--100) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
