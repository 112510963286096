blockquote,
.fr-view blockquote {
  border-left: 5px solid var(--has-color);
  color: var(--has-color);
  padding-left: 1.5rem;
  font-size: 1.25rem;

  footer {
    font-size: 1rem;
    color: var(--has-color);
    opacity: 0.8;
  }
}
