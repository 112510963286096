.app-main {
  z-index: 1000;
  *:last-child {
    margin-bottom: 0;
  }

  /* app-main default link focus style
  =========================================================================== */
  a {
    border-radius: 0.05rem;
    text-decoration: none;

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 4px;
      box-shadow: 0 0 0 2px map_get($app-colors, "neutral--100"),
        0 0 0 4px map_get($app-colors, "blue--700");
    }

    &:not(.btn):hover {
      text-decoration: underline;
    }
  }

  > section {
    position: relative;
  }

  ::selection, span.highlight {
    background-color: var(--orange--100);
    color: var(--neutral--900);
  }

  span.highlight::selection {
    background-color: var(--orange--500);
  }
}
