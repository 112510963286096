// Component
@mixin global-component {
  flex: 1;
  padding: ($spacer * 2) $spacer;

  @include media-breakpoint-up(lg) {
    padding-left: $spacer * 4;
    padding-right: $spacer * 4;
  }
}

@mixin global-component__description {
  line-height: 1.85;
  max-width: 900px;
  padding-bottom: $spacer;

  @include media-breakpoint-up(md) {
    font-size: 1.1em;
    line-height: 1.75;
  }
}

@mixin global-component__cta-buttons {
  .btn {
    margin-top: $spacer * 1.5;
    width: 100%;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer;
      margin-top: $spacer;
      width: auto;
    }
  }
}

@mixin global-focus-ring ($accent: var(--blue--700)) {
  box-shadow: 0 0 0 3px var(--neutral--100), 0 0 0 6px $accent;
}

@mixin global-separator {
  content: "";
  display: block;
  height: 1rem;
  width: 1px;
  background-color: var(--neutral--400);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

@mixin global-reset-container {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

@mixin global-reverse-anchor-decoration ($useImportant: null) {
  text-decoration: none #{$useImportant};
  &:hover,
  &:focus {
    text-decoration: underline #{$useImportant};
  }
}

@mixin global-bordered-heading {
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--neutral--300);
  img {
    margin-right: 0.5rem;
  }
}

@mixin ios-13-below() {
	@supports (-webkit-touch-callout: none) and (not (translate: none)) {
		@content;
	}
}