.marketing-doctors {
  background: var(--blue--100);
  text-align: center;

  .container {
    > p:last-of-type {
      margin-bottom: 2rem;
    }
  }
  &__item {
    padding: 0 2rem;
    h3 {
      font-weight: 700;
    }
    p {
      font-size: 1.25rem;
    }
    &-content {
      opacity: 0;
    }
  }

  img {
    opacity: 0.5;
    margin-bottom: 2rem;
  }

  .swiper-slide-active {
    .marketing-doctors__item-content,
    img {
      opacity: 1;
    }
  }

  .metric-rating {
    justify-content: center;
  }
}
