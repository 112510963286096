/*! purgecss start ignore */

// Approved background colors

$backgrounds: (
  "neutral--100": map-get($app-colors, "neutral--100"),
  "neutral--300": map-get($app-colors, "neutral--300"),
  "blue--900": map-get($app-colors, "blue--900"),
  "blue--800": map-get($app-colors, "blue--800"),
  "blue--600": map-get($app-colors, "blue--600"),
  "blue--100": map-get($app-colors, "blue--100"),
  "orange--900": map-get($app-colors, "orange--900"),
  "orange--500": map-get($app-colors, "orange--500"),
  "orange--100": map-get($app-colors, "orange--100"),
  "purple--500": map-get($app-colors, "purple--500"),
  "purple--100": map-get($app-colors, "purple--100"),
);
.bg {
  --has-background: var(--neutral--100);
  --has-color: var(--nuetral--900);

  background: var(--has-background);
  color: var(--has-color);

  // Override anchors so they're the same color as the copy.
  a:not(.btn, :disabled) {
    color: var(--has-color);
    text-decoration: underline;
    &:hover,
    &:focus {
      opacity: 0.7;
      text-decoration: none;
    }
  }
}

@include make-backgrounds($backgrounds);

/*! purgecss end ignore */
