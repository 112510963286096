.template-location {
  .subpage__content > img:first-child {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top center;
    @include media-breakpoint-up(lg) {
      height: 450px;
    }
  }
}