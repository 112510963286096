// General Swiper overrides
.swiper {
  --swiper-button-top: 50%;
  --swiper-navigation-size: 20px;
  --swiper-theme-color: var(--blue--700);
}

.swiper-pagination {
  position: relative;
  top: auto;
  bottom: auto;
}
.swiper-button-prev,
.swiper-button-next {
  top: var(--swiper-button-top, 50%);

  @include slider-button;
}

.bg-purple--500,
.bg-blue--600,
.bg-blue--800,
.bg-blue--900,
.bg-orange--500,
.bg-orange--900 {
  .swiper-button-next,
  .swiper-button-prev {
    background: transparent;
    color: var(--neutral--100);
    border-color: var(--neutral--100);

    &:hover,
    &:focus-visible {
      background: rgba(255, 255, 255, 0.5);
      color: var(--neutral--900);
    }

    &:focus-visible {
      outline-color: var(--neutral--100);
      border-color: var(--neutral--100);
      box-shadow: 0 0 0 3px var(--neutral--900), 0 0 0 6px var(--neutral--100);
    }

    &:active {
      background: transparent;
      color: var(--neutral--100);
    }
  }
}
