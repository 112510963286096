a:has(img) {
  display: inline-block;
}

a:has(img[style*="float"], img.fr-dii) {
  display: inline;
  &::after {
    clear: none !important;
    content: none !important;
    display: none !important;
  }
  .icon-external-link {
    display: none !important;
  }
}

.figure > a {
  display: block;
  margin-bottom: 0.5rem;
}

figcaption {
  font-style: italic;
}

.figure-img {
  width: 100%;
  height: auto;
}

.row-cols-1 {
  .figure {
    display: block;
    margin: auto;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
}

.row-cols-md-2,
.row-cols-md-3,
.row-cols-md-4 {
  .figure,
  .figure-img {
    width: 100%;
  }
}

.figure.float-start,
.figure.float-left,
.figure[style*="float:left"],
.figure[style*="float: left"],
.figure.float-end,
.figure.float-right,
.figure[style*="float:right"],
.figure[style*="float: right"] {
  display: inline-block;
  margin-right: 1.5rem;
  max-width: 50%;
}

.figure.float-end,
.figure.float-right,
.figure[style*="float:right"],
.figure[style*="float: right"] {
  margin-right: auto;
  margin-left: 1.5rem;
}

.row-cols-md-2,
.row-cols-md-3,
.row-cols-md-4 {
  .figure,
  .figure-img {
    width: 100%;
  }
}

.figure .icon-external-link {
  display: none !important; // just in case
}
