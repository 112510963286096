.modal-cta-button {
  z-index: 20000;
}

.modal-sticky {
  z-index: 15000;
}

.modal {
  z-index: 10000;
}

.modal-backdrop {
  z-index: 9000;
}

.skip-to-content {
  z-index: 5000;
}
.app-header {
  z-index: 2000;
}

.app-footer {
  z-index: 1000;
}

.app-main {
  z-index: 500;
}
