.modal-blue {
  .bg {
    --has-color: var(--neutral--100);
    --has-background: var(--blue--700);
  }

  h2 {
    margin-bottom: 2rem;
    color: var(--has-color);
  }
  p {
    font-size: 1.25rem;
  }

  @include media-breakpoint-down(sm) {
    --bs-modal-margin: 0;
  }
}

.modal {
  .close {
    position: absolute;
    display: block;
    height: 3rem;
    width: 3rem;
    font-size: 2rem;
    background: var(--blue--700);
    color: white;
    text-align: center;
    line-height: 3rem;
    top: -10px;
    right: -10px;
    border-radius: 0;
    border-bottom-left-radius: 0.5rem;
    padding: 0;
    z-index: 1000;

    @include media-breakpoint-up(sm) {
      top: -1.5rem;
      right: -1.5rem;
      border-radius: 100px;
    }
    &:hover {
      background-color: var(--blue--900);
    }
    span {
      position: relative;
      top: -2px;
    }
  }
}

.modal-content {
  border: 10px solid var(--blue--700);
  position: relative;
  > .btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1000;
  }
}

.modal-loading {
  position: absolute;
  z-index: 500;
  inset: 0;
  background: white;
  fill: var(--neutral--300);
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  svg {
    animation: loading 1500ms linear infinite;
  }
}

.modal[data-loading="true"] {
  .modal-content {
    min-height: 400px;
  }
  .modal-loading {
    display: flex;
  }
  .modal-body,
  .modal-header,
  .modal-header {
    display: none;
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.modal-cta-button {
  position: fixed;
  display: none;
  &[aria-hidden="false"] {
    display: block;
  }
  &[data-position-y="top"] {
    top: 1rem;
  }
  &[data-position-y="bottom"] {
    bottom: 1rem;
  }
  &[data-position-x="right"] {
    right: 1rem;
  }
  &[data-position-x="left"] {
    left: 1rem;
  }
  &[data-position-x="center"] {
    left: 50%;
    transform: translateX(-50%);
  }
}

.modal-sticky {
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  a {
    display: block;
    background: var(--orange--500);
    color: var(--neutral--900);
    padding: 1rem;
    text-align: center;
    font-weight: 700;
    opacity: 1;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}


.modal[data-hook="modalCTA"] {
  .modal-content {
    overflow: visible;
  }
}

