.event {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;

  border-bottom: 2px solid var(--neutral--300);

  &:last-child {
    border-bottom: none;
  }

  @include ios-13-below() {
    > * {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 3rem;
    // border-bottom: none;
  }

  &-date {
    min-width: 80px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1;
    span {
      display: block;
    }
    &--day {
      font-size: 3.5rem;
      font-weight: 600;
    }

    @include media-breakpoint-up(md) {
      &::after {
        position: absolute;
        display: block;
        content: "";
        right: calc(-1.5rem + 1px);
        top: 0;
        width: 1px;
        height: 7rem;
        background: var(--neutral--300);
      }
    }
  }

  &-content {
    flex-grow: 1;
    font-size: 0.85rem;
    p,
    ul {
      margin-bottom: 0.125rem;
    }
    h4,
    h5 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  a {
    --has-color: var(--blue--700);
  }
}

.event-featured {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.event-one-time {
  &__filter {
    padding: 1rem 0;
    margin-bottom: 2rem;
    border-top: 2px solid var(--neutral--300);
    border-bottom: 2px solid var(--neutral--300);
    font-weight: 700;
    label {
      cursor: pointer;
    }
  }

  .event {
    &-content {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      font-size: 1rem;
    }
  }
}

.event-recurring {
  margin-bottom: 2rem;
  &__event {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    h4 span {
      font-weight: 300;
      font-size: 0.8em;
    }
    time {
      display: block;
      margin-bottom: 0.5rem;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--neutral--300);
    }
  }
}

.event-upcoming {
  ul {
    margin-bottom: 2rem;
  }

  &__simple-list {
    a {
      font-weight: 700;
    }
  }

  &__image-list {
    li {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 1.5rem;
      position: relative;
      @include ios-13-below {
        > * {
          margin: 0 0 0.5rem;
        }
      }
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        gap: 1rem;
        @include ios-13-below {
          > * {
            margin: 0 0.5rem;
          }
        }
      }
    }

    img {
      @include media-breakpoint-up(sm) {
        max-width: 250px;
        width: 50%;
      }
    }

    a {
      font-weight: 700;
      font-size: 1.25rem;
    }

    .event-upcoming__content {
      flex-grow: 1;
    }

    time {
      display: block;
    }
  }
}

.event-hero {
  position: relative;
  margin-bottom: 2rem;
  img {
    height: 600px;
    width: 100%;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }

  .event {
    background: rgba(255, 255, 255, 0.8);

    // If there is an image, then we'll position it over the image. 
    &:not(:first-child) {
      position: absolute;
      padding: 1.5rem;
      width: calc(100% - var(--bs-gutter-x));
      bottom: 0;
    }

    &-content {
      font-size: 1rem;
    }
  }
}
