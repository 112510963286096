.pagination {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    a {
      display: block;
      padding: 0.5rem .75rem;
      font-size: 1.125rem;
      font-weight: 700;
      border-bottom: 2px solid transparent;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none !important;
        color: var(--neutral--900);
        border-bottom-color: var(--neutral--900);
      }
      &[aria-current="page"] {
        color: var(--orange--900);
        border-bottom-color: var(--orange--900);
      }
    }
  }
}
