.hub-hero {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  @include media-breakpoint-down(md) {
    // Break the container on mobile views.
    padding: 0;
    width: 100%;
    max-width: none;
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  

  &__figure {
    margin-bottom: 0;
  }
  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__content {
    padding: 1.5rem;
  }

  .btn {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
