.class-listings {

  &__day {
    margin-bottom: 3rem;
  }

  &__class {
    margin-bottom: 2rem;
  }

  &__time {
    margin-bottom: .5rem;
    font-size: 1.125rem;
  }
}