.marketing-main {
  padding-top: 140px;
  a {
    color: var(--orange--900);
  }
  h1,
  h2 {
    font-family: "Adelle", "Roboto";
    font-weight: 700;
    color: var(--blue--600);
  }
  h1 {
    font-size: 3rem;
    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }
  h2 {
    line-height: 1.2;
    font-size: 2rem;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
  }
  p {
    font-size: 1.2rem;
  }

  .modal-blue {
    h2 {
      font-family: "Adelle", "Roboto";
    }
  }

  h2 + .row {
    margin-top: 0;
  }
}

.marketing-section {
  @include marketing-section;
}
