// FOOTER
// =============================================
.microsite-footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--ms-footer-background);
  color: var(--ms-footer-color);
  a {
    color: var(--ms-footer-color);
  }

  hr {
    --hr: var(var(--ms-footer-color));
    opacity: 0.6;
  }
}