.microsite-main {
  .tabs {
    --tabs-trigger-background: var(--ms-nav-background);
    --tabs-trigger-color: var(--ms-nav-color);
    --tabs-trigger-hover-background: var(--ms-nav-hover-background);
    --tabs-trigger-hover-color: var(--ms-nav-hover-color);
    --tabs-trigger-active-background: var(--ms-nav-active-background);
    --tabs-trigger-active-color: var(--ms-nav-active-color);
    &__panels {
      position: relative;
    }
  }
}
