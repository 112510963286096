:root {
  --list-depth-offset: 1.25rem;
  --dropdown-width: 20px;
}

// NJHK13-388 Left Rail Nav

.subpage__aside-nav {
  position: relative;

  // Default adjustments
  a {
    padding: 0.5rem 0;
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--neutral--900);
    flex-grow: 1;
    margin: 0 6px; // offset the focus ring
    &:focus {
      @include global-focus-ring;
    }
  }

  h2 {
    margin: 0;
    a {
      font-size: 1.5rem;
    }
  }

  &-skip {
    position: absolute;
    padding: 0.5rem;
    bottom: 100%;
    left: 0;
  }

  // the dropdown button
  &-trigger {
    padding: 0;
    margin: 0;
    border: none;
    width: var(--dropdown-width);

    &:focus {
      @include global-focus-ring;
    }
    span {
      width: var(--list-depth-offset);
    }

    &[aria-expanded="true"] {
      span {
        rotate: 90deg;
      }
    }
  }

  // dropdown wrapper div
  &-dropdown {
    display: flex;
    flex-direction: row;

    // Paddings should match li's
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  // List adjustments
  // Reset
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  > ul {
    border-bottom: 1px solid var(--neutral--300);
  }

  li {
    border-top: 1px solid var(--neutral--300);
  }

  li {
    // Paddings should match li's
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    a {
      padding-left: var(--dropdown-width);
    }
    &.has-children {
      padding-top: 0;
      padding-bottom: 0;
      a {
        padding-left: 0;
      }
    }
    h2 a {
      padding-left: 0;
      margin-left: 0;
    }
  }

  // Target the "parent" list item so that the entire list is left aligned to the container.
  > ul > li.subpage__aside-nav-parent {
    padding: 0;
    border-top: none;
  }

  // Manage indents
  > ul ul ul li {
    padding-left: var(--list-depth-offset);

    // Account for the width of the dropdown in items that have no children.
    &:not(.has-children) {
      padding-left: calc(var(--list-depth-offset) + var(--dropdown-width));
    }

    a {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  // Current page
  .is-self {
    &:not(.has-children),
    > .subpage__aside-nav-dropdown {
      background-color: var(--orange--100);
    }
  }
}
