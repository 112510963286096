.marketing-footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--blue--700);

  ul {
    a {
      font-weight: 700;
      @include global-reverse-anchor-decoration;
    }
  }
}

.modal-insurance {
  h2 {
    font-family: "Adelle", "Roboto";
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.25rem;
  }

  .bg {
    --has-color: var(--neutral--100);
    --has-background: var(--blue--700);
  }
}
