/*! purgecss start ignore */

a:has(span.btn) {
  display: inline-block;
  border-radius: 0.5rem;
  padding: 0;
}

/* Adjust global button styles
=========================================================================== */
.btn,
a.btn,
button,
button.btn,
input.btn,
a:not(.btn) span.btn {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--nuetral--900);
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.65;
  padding: 0.75rem 2rem;
  // position: relative;
  text-decoration: none;
  text-align: center;
  //text-transform: uppercase;
  border-radius: 0.5rem;
  cursor: pointer;

  &:focus-visible {
    outline: 3px solid transparent;
    @include global-focus-ring
  }

  &:not(:disabled):not(.disabled):hover &:not(:disabled):not(.disabled):focus {
    outline: 3px solid transparent;
    outline-offset: 6px;
    text-decoration: none;
  }

  .external-link-icon {
    margin-right: 0 !important;
  }

  &.btn-default {
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
  }
  &.btn-sm {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    border-radius: 0.25rem;
  }
  &.btn-lg {
    padding: 1rem 2.25rem;
    font-size: 1.5rem;
    border-radius: 0.65rem;
  }

  &.btn-round {
    border-radius: 100px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

// Approved Buttons
$buttons: (
  "neutral--900": (
    default: var(--neutral--900),
    hover: var(--neutral--700),
    focus-ring: var(--neutral--900),
    color: var(--neutral--100),
    hover-color: var(--neutral--100),
  ),
  "neutral--300": (
    default: var(--neutral--300),
    hover: var(--neutral--500),
    focus-ring: var(--neutral--300),
    color: var(--neutral--900),
    hover-color: var(--neutral--100),
  ),
  "neutral--100": (
    default: var(--neutral--100),
    hover: var(--neutral--300),
    focus-ring: var(--neutral--100),
    color: var(--neutral--900),
    hover-color: var(--neutral--900),
  ),
  "blue--700": (
    default: var(--blue--700),
    hover: var(--blue--900),
    focus-ring: var(--blue--900),
    color: var(--neutral--100),
    hover-color: var(--neutral--100),
  ),
  "orange--900": (
    default: var(--orange--900),
    hover: var(--orange--500),
    focus-ring: var(--orange--500),
    color: var(--neutral--100),
    hover-color: var(--neutral--900),
  ),
  "orange--500": (
    default: var(--orange--500),
    hover: var(--orange--900),
    focus-ring: var(--orange--900),
    color: var(--blue--900),
    hover-color: var(--neutral--100),
  ),
  "purple--500": (
    default: var(--purple--500),
    hover: var(--purple--100),
    focus-ring: var(--purple--500),
    color: var(--neutral--100),
    hover-color: var(--neutral--900),
  ),
);

@include make-buttons($buttons);
@include make-outline-buttons($buttons);

button.hide-on-click[data-bs-toggle="collapse"][aria-expanded="true"] {
  display: none;
}


a.stretched-link:focus {
  box-shadow: none;
  &::after {
    @include global-focus-ring();
  }
}

/*! purgecss end ignore */
